<div class="h-screen w-screen bg-topvix-bg">

  @if(!isCustomPage) {

  <router-outlet *ngIf="isLoginPage"></router-outlet>

  <div *ngIf="!isLoginPage">
    <app-navbar
      *ngIf="isBrowser"
      [isHidden]="isLoginPage || !isToShowHeader"
      (toggleSideNav)="toggleSidNav()"
      [wsConnection]="wsConnectionStatus"
      [qtdConnected]="qtdWSConnections"
      [broadcast]="broadcastMessage"
      [message]="messageFromServer"
      [pendencias]="countPendencias"
    ></app-navbar>
  </div>

  <div
    class="{{
      isLoginPage ? 'hidden' : ''
    }} flex flex-row justify-start items-stretch h-[93vh] overflow-hidden w-auto"
  >
    <side-nav
      *ngIf="isBrowser"
      class="{{ sideNavVisible ? 'block' : 'hidden md:block' }}"
      (onToggleSideNav)="onToggleSideNav($event)"
      [ngClass]="
        currentIdleStatus === 'TIMEOUT'
          ? 'hidden'
          : isMobile
          ? 'w-13 lg:w-64'
          : 'w-auto'
      "
    ></side-nav>

    <div
      class="flex flex-col justify-center items-stretch overflow-hidden bg-gray-50 w-screen"
    >
      <app-breadcrumb
        *ngIf="isBrowser"
        [isHidden]="isLoginPage || !isToShowBreadcrumb"
        [currentUrl]="currentUrl"
      ></app-breadcrumb>

      <div
        class="overflow-y-hidden h-area-central-xsm md:h-area-central flex flex-col justify-start items-start p-0 bg-topvix-bg overflow-x-hidden"
      >
        <app-home
          class="h-full w-full"
          [collapsed]="isSideNavCollapsed"
          [screenWidth]="screenWidth"
        >
          <router-outlet @routeAnimations *ngIf="!isLoginPage"></router-outlet>
        </app-home>
      </div>

      <app-footer *ngIf="isBrowser" [isHidden]="isLoginPage || !isToShowFooter"></app-footer>
    </div>
  </div>

  } @if(isCustomPage) {

    <router-outlet *ngIf="isBrowser" class="h-full w-full" @routeAnimations></router-outlet>

  }
</div>
