import {
  afterNextRender,
  AfterViewInit,
  Component,
  DoCheck,
  Inject,
  inject,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationService } from './services/authorization.service';
import { FaviconService } from './services/favicon.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ISideNavToggle } from './models/SideNav.data.model';
import { HomeComponent } from './pages/home/home.component';
import { IdleService } from './services/idle.service';
import {
  eCountPendenciasEmpresa,
  eWsStatus,
  idleStatusType,
} from './enums/Enums';
import { slideInAnimation } from './components/animations/sidenav.animations';
import { AccountService } from './services/account.service';

import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

import { UtilitiesService } from './services/utilities.service';
import { differenceInCalendarDays } from 'date-fns';
import { WebsocketService } from './services/websocket.service';
import { Empresa } from './models/Account.data.model';
import { IEventMessageEntity, IQtdConnections } from './models/WS.data.model';
import { SeoService } from './services/seo.service';
import { CampanhaService } from './services/recursos.service';
import { IApiResponse } from './interfaces/api-response.interface';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [
    RouterOutlet,
    BreadcrumbComponent,
    FooterComponent,
    CommonModule,
    NavbarComponent,
    SidenavComponent,
    HomeComponent,
  ],
  animations: [slideInAnimation],
})
export class AppComponent
  implements OnInit, OnDestroy, DoCheck, AfterViewInit, OnChanges
{
  title = 'New Topvix';

  router = inject(Router);

  isCustomPage = false;
  isLoginPage = false;
  currentUrl = '';

  isToShowHeader = true;
  isToShowBreadcrumb = true;
  isToShowFooter = true;
  isToShowSideBar = true;

  sideNavVisible = true;

  isTokenVencido?: boolean;
  currentIdleStatus?: idleStatusType;

  isMobile = false;

  // isWSConnected = false;
  qtdWSConnections: IQtdConnections = { qtd_connected: 0, qtd_logged: 0 };
  wsConnectionStatus?: eWsStatus;
  broadcastMessage?: IEventMessageEntity;
  messageFromServer?: IEventMessageEntity;
  countPendencias?: eCountPendenciasEmpresa;

  wantToOpen = false;

  //SIDENAV CONTROLS
  isSideNavCollapsed = false;
  screenWidth = 0;

  currentAccount?: Empresa;

  idleSubscription?: Subscription;
  routeSubscription?: Subscription;
  querySubscription?: Subscription;
  authenticationSubcription?: Subscription;
  currentAccountSubcription?: Subscription;
  wsStatusSubscription?: Subscription;
  wsQtdConnectedSubscription?: Subscription;
  wsBroadcastSubscription?: Subscription;
  wsMessageSubscription?: Subscription;
  wsPendenciasAdminSubscription?: Subscription;
  wsPendenciasManagerSubscription?: Subscription;

  currentSubdominio: string | undefined;

  meu_device: any;
  meu_ip: string | undefined;
  geo_data?: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authorizationService: AuthorizationService,
    private readonly accountService: AccountService,
    private readonly faviconService: FaviconService,
    private readonly idleService: IdleService,
    private readonly activatedRouter: ActivatedRoute,
    private readonly utility: UtilitiesService,
    private readonly wsService: WebsocketService,
    private readonly seo: SeoService,
    private readonly campanhaService: CampanhaService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // initFlowbite();
    // this.primeNgConfig.inputStyle.set('outlined');

    // this.wsConnectionStatus = this.wsService.getWSConnectedV2();

    afterNextRender(() => {
      // Safe to check `scrollHeight` because this will only run in the browser, not the server.

      console.log(
        '[ App ] afterNextRender',
        'B:',
        this.isBrowser,
        'S:',
        this.isServer
      );

      
      this.faviconService.updateFavicon();

      this.idleSubscription = this.idleService
        .idleStatusObserver()
        .subscribe((value) => {
          console.log('[ App ] idle status:', value);

          this.currentIdleStatus = value;
        });

      this.wsStatusSubscription = this.wsService
        .isWSConnectedV2Observer()
        .subscribe((value) => {
          console.log('WS_Socket - CONECTADO V2? (app):', value);
          this.wsConnectionStatus = value;
        });

      this.wsQtdConnectedSubscription = this.wsService
        .isWSQtdConnectionsObserver()
        .subscribe((value) => {
          console.log('WS_Socket - Qtd. Conexões?:', value);
          if (value) this.qtdWSConnections = value;
        });

      this.wsBroadcastSubscription = this.wsService
        .wsBroadcastMessageFromServerObserver()
        .subscribe((value) => {
          console.log('WS_Socket - Broadcast:', value);
          if (value) this.broadcastMessage = value;
        });

      this.wsMessageSubscription = this.wsService
        .wsMessageFromServerObserver()
        .subscribe((value) => {
          console.log('WS_Socket - Message from server:', value);
          if (value) this.messageFromServer = value;
        });

      this.wsPendenciasAdminSubscription = this.wsService
        .wsQTDPendenciasAdminObserver()
        .subscribe((value) => {
          console.log('WS_Socket - Pendências para ADMIN?:', value);
          if (value) this.countPendencias = value;
        });

      this.wsPendenciasManagerSubscription = this.wsService
        .wsQTDPendenciasManagerObserver()
        .subscribe((value) => {
          console.log('WS_Socket - Pendências para MANAGER?:', value);
          if (value) this.countPendencias = value;
        });
    });
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get isServer() {
    return isPlatformServer(this.platformId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('[ App ] changes:', changes, 'width:', this.screenWidth);
  }

  /**
   * AQUI É COMPLETAMENTE PROCESSADO DO LADO DO SERVIDOR
   */
  ngOnInit(): void {
    // debugger;

    this.getLocalClientData();
    
    this.seo.initDefaultMetaTags();

    if (this.isBrowser) {
      // this.getLocalClientData();
      // this.faviconService.updateFavicon();
    }

    this.authenticationSubcription = this.authenticationService
      .getTokenVencidoObserver()
      .subscribe((value) => {
        // console.log('AppComponent - token vencido?', value);
        this.isTokenVencido = value;

        if (value) this.router.navigateByUrl('expired');

        // if (value && this.authenticationService.currentToken.refreshToken)
        //   this.authenticationService.requestRefreshAccessToken(this.authenticationService.currentToken.refreshToken);
      });

    this.currentAccountSubcription = this.accountService
      .currentEmpresaObserver()
      .subscribe(async (value) => {
        if (value && value.empresa_id) {
          this.currentAccount = value;

          if (value?.imagem) {
            this.faviconService.setFaviconUrl(value.imagem);
          }

          //atribui os valores definidos para a área do cliente
          const _config =
            await this.accountService.getAreaClienteConfigListByEmpresa(
              value.empresa_id
            );

          if (_config && _config.length) {
            this.accountService.setCurrentAreaClienteConfig(_config[0]);
          }
        }
      });

    this.routeSubscription = this.activatedRouter.params.subscribe((value) => {
      console.log(
        '[ App ] activatedRouter.params:',
        value,
        'B:',
        this.isBrowser,
        'S:',
        this.isServer
      );
    });

    this.querySubscription = this.activatedRouter.queryParams.subscribe(
      async (value) => {
        console.log(
          '[ App ] activatedRouter.queryParams:',
          value,
          'B:',
          this.isBrowser,
          'S:',
          this.isServer
        );

        // parametro para LINK DE SORTEIO
        if (value && value['id']) {
          const sorteio_id = value['id'];

          console.log('[ App ] id:', sorteio_id);

          const _sorteio_id = Number.parseInt(sorteio_id);

          if (_sorteio_id) {
            //busca os dados do sorteio do link

            const _sorteio = await this.campanhaService
              .getCampanhaSorteioById(_sorteio_id)
              .catch((error: IApiResponse) => {
                console.error(
                  '[ App ] erro ao obter dados do sorteio pelo id:',
                  error
                );
              });

            console.log(
              '[ LinkCadastroCliente - getEmpresaFromUrl ] SORTEIO DADOS:',
              _sorteio
            );

            if (_sorteio) {
              if (_sorteio?.Empresa && _sorteio?.Empresa?.nome_fantasia) {
                this.seo.setTitle({
                  title: _sorteio?.Empresa?.nome_fantasia,
                });
              }

              if (_sorteio.ImagensSorteio && _sorteio.ImagensSorteio.length) {
                if (_sorteio.ImagensSorteio[0].imagem) {
                  this.faviconService.setFaviconUrl(
                    _sorteio.ImagensSorteio[0].imagem
                  );
                  this.faviconService.updateFavicon();

                  console.log(
                    '[ LinkCadastroCliente ] sorteio imagens:',
                    _sorteio.ImagensSorteio
                  );

                  const _imagem_url = `${environment.SERVER_NAME}/${environment.SERVER_LINK_IMAGE_DIR}/${_sorteio.ImagensSorteio[0].url}`;

                  this.seo.setMetaData({
                    title: _sorteio.Empresa?.nome_fantasia,
                    description: _sorteio.titulo_sorteio,
                    image_url: _imagem_url,
                    image_width: _sorteio.ImagensSorteio[0].imagem_width,
                    author: 'AJSystems',
                  });

                  this.seo.setTitle({
                    title: _sorteio.Empresa?.nome_fantasia,
                  });
                }
              }

              this.campanhaService.setCurrentSorteio(_sorteio);
            }
          }
        }

        // parametro para LINK DE CADASTRO
        if (value && value['empid']) {
          const link_cadastro = value['empid'];

          const _linkEmpresa = await this.accountService
            .getLinkCadastroById(link_cadastro)
            .catch((error: IApiResponse) => {});

          console.log(
            '[ LinkCadastroCliente - getEmpresaFromUrl ] empresa:',
            _linkEmpresa
          );

          if (_linkEmpresa) {
            const _imagem_url = `${environment.SERVER_NAME}/${environment.SERVER_LINK_IMAGE_DIR}/${_linkEmpresa.url}`;

            this.seo.setMetaData({
              title: _linkEmpresa.nome_empresa,
              description: _linkEmpresa?.titulo_link,
              image_url: _imagem_url,
              image_width: _linkEmpresa.imagem_width,
              author: 'AJSystems',
            });

            this.seo.setTitle({
              title: _linkEmpresa.nome_empresa,
            });

            if (_linkEmpresa)
              this.accountService.setCurrentLinkCadastro(_linkEmpresa);
          }
        }

        // parametro para ÁREA DO CLIENTE
        if (value && value['id_loja']) {
          const link_id = value['id_loja'];

          const _configAreaCliente = await this.accountService
            .getAreaClienteConfigByLinkId(link_id)
            .catch((error: IApiResponse) => {});

          console.log(
            '[ LinkAreaCliente - Config ]',
            _configAreaCliente
          );

          if (_configAreaCliente) {
            const _imagem_url = `${environment.SERVER_NAME}/${environment.SERVER_LINK_IMAGE_DIR}/${_configAreaCliente.url}`;

            this.seo.setMetaData({
              title: _configAreaCliente.nome_programa,
              description: _configAreaCliente?.descricao,
              image_url: _imagem_url,
              image_width: _configAreaCliente.imagem_width,
              author: 'AJSystems',
            });

            this.seo.setTitle({
              title: _configAreaCliente.nome_programa,
            });

            if (_configAreaCliente)
              this.accountService.setCurrentAreaClienteConfig(_configAreaCliente);
          }
        }
      }
    );

    // this.wsService.isWSConnectedObserver().subscribe((value) => {
    //   console.log('WS_Socket - Está conectado?:', value);
    //   this.isWSConnected = value;
    // });

    //se for um refresh de página e já tiver o token, então conecta no websocket
    if (this.wsService.connect()) {
      // debugger;
      this.wsService.resendUserLogin();
    }
  }

  async getLocalClientData() {
    const _local = `[B:${this.isBrowser}][S:${this.isServer}]`;

    this.meu_device = `nada ainda. ${_local}`;
    this.meu_ip = `nada ainda. ${_local}`;

    // if (this.isServer) return;

    const _userData = await this.utility.getLocalUserData().catch((error) => {
      console.log('[ App ] erro ao obter dados locais do usuário:', error);
      this.geo_data = JSON.stringify(error);
    });

    if (_userData) {
      const { device, ip } = _userData;

      this.meu_device = `${JSON.stringify(device)} ${_local}`;
      this.meu_ip = `${ip} ${_local}`;

      const _localGeoData = this.authenticationService.getGeolocationData();
      this.geo_data = JSON.stringify(_localGeoData);

      //verificar apenas uma vez por dia
      const _hoje = new Date();

      const _dias =
        _localGeoData && _localGeoData.last_checked
          ? differenceInCalendarDays(_hoje, _localGeoData.last_checked)
          : 0;

      console.log(
        '[ App ] geolocation - ultima verificação:',
        _localGeoData?.last_checked,
        'dias passados:',
        _dias
      );

      if ((ip && !_localGeoData) || (ip && _dias >= 1)) {
        const _geoData = await this.utility
          .getClientGeodata(ip)
          .catch((error) => {
            this.geo_data = JSON.stringify(error);
          });

        if (_geoData) {
          this.authenticationService.setGeolocationData(_geoData);
        }
      }
    }

    if (environment.LINK_CADASTRO_FORMAT === 'subdominio') {
      // this.currentSubdominio = this.subdomainService.getSubdomainName();
      // console.log('[ App ] subdominio:', this.currentSubdominio);
      // console.log('[ App ] subdominio - currentUrl:', this.currentUrl);
      // const _rota = this.currentUrl
      // console.log()
      // if(this.currentSubdominio === 'zecoxinha') {
      //   this.router.navigateByUrl(environment.LINK_CADASTRO_ROTA);
      // }
    }
  }

  ngOnDestroy(): void {
    this.wsService.performWSUserLogoff();
    this.wsService.disconnect();
    this.authenticationService.userLogoff();

    if (this.idleSubscription) this.idleSubscription.unsubscribe();
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.querySubscription) this.querySubscription.unsubscribe();
    if (this.authenticationSubcription)
      this.authenticationSubcription.unsubscribe();
    if (this.currentAccountSubcription)
      this.currentAccountSubcription.unsubscribe();
    if (this.wsStatusSubscription) this.wsStatusSubscription.unsubscribe();
    if (this.wsQtdConnectedSubscription)
      this.wsQtdConnectedSubscription.unsubscribe();
    if (this.wsBroadcastSubscription)
      this.wsBroadcastSubscription.unsubscribe();
    if (this.wsMessageSubscription) this.wsMessageSubscription.unsubscribe();
  }

  ngDoCheck(): void {
    this.currentUrl = this.router.url;
    // console.log('[ App ] DoCheck -> página atual:', this.currentUrl,
    //   'autenticado?:', this.authenticationService.isAuthenticated,
    //   'autorizado?:', this.authorizationService.isAuthorized);

    if (
      this.currentIdleStatus === 'TIMEOUT' ||
      this.idleService.currentIdleStatus === 'TIMEOUT'
    ) {
      // this.router.navigateByUrl('/expired');
      // return;
    }

    this.isLoginPage = false;
    if (
      this.currentUrl == '/' ||
      this.currentUrl.includes('/login') ||
      this.currentUrl.includes(
        environment.API_AUTH.Methods.CUSTOMER_AUTH_LOGIN
      ) ||
      this.currentUrl.includes(
        environment.API_AREA_CLIENTE.Methods.ROTA_AREA_CLIENTE
      ) ||
      this.currentUrl.includes('/reset') ||
      this.currentUrl.includes('/reset-senha-cliente') ||
      this.currentUrl.includes('/register') ||
      this.currentUrl.includes('/cadastrar')
    ) {
      this.isLoginPage = true;
    }

    if (
      this.currentUrl.includes(environment.LINK_CADASTRO_ROTA) ||
      this.currentUrl.includes(environment.LINK_CADASTRO_SORTEIO_ROTA) ||
      this.currentUrl.includes(environment.LINK_AREA_CLIENTE_ROTA) ||
      this.currentUrl.includes(
        environment.API_AREA_CLIENTE.Methods.ROTA_AREA_CLIENTE
      )
    ) {
      this.isCustomPage = true;
    }

    if (this.currentUrl.includes('/expired')) {
      this.isLoginPage = false;

      this.isToShowFooter = true;
      this.isToShowHeader = true;
      this.isToShowSideBar = true;
      this.isToShowBreadcrumb = true;

      this.currentIdleStatus = 'TIMEOUT';
    }

    if (
      !this.isLoginPage &&
      !this.authenticationService.isAuthenticated &&
      !this.isCustomPage
    ) {
      this.gotoLogin();

      // this.router.navigateByUrl('/');
    }
  }

  ngAfterViewInit(): void {
    // // initFlowbite();
  }

  gotoLogin() {
    const _areaLogged = this.authorizationService.getLastLoginAreaFromHistory();

    if (_areaLogged && _areaLogged === 'cliente') {
      this.authenticationService.customerLogoff();
      this.router.navigateByUrl(
        environment.API_AUTH.Methods.CUSTOMER_AUTH_LOGIN
      );
      return;
    }
    this.authenticationService.userLogoff();
    this.wsService.performWSUserLogoff();
    this.router.navigateByUrl('/');
  }

  // SIDENAV
  onToggleSideNav(data: ISideNavToggle) {
    console.log('[ App ] sidenav emitindo:', data);
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
    this.isMobile = data.mobile;
  }

  toggleSidNav() {
    // this.isSideNavCollapsed = !this.isSideNavCollapsed
    this.sideNavVisible = !this.sideNavVisible;
  }

  /**
   * Será usado para realizar o refresh do sistema quando subir alguma atualização
   * e não precisar entrar novamente no sistema e nem esperar que o usuário
   * faça o refresh manualmente.
   */
  reloadComponent() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/']);
    });
  }
}
