import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UserService } from '../../services/user.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/Custom.validators';
import { TextFieldComponent } from '../text-field/text-field.component';
import {
  SelectFieldComponent,
  selectFieldDataModel,
} from '../select-field/select-field.component';
import { ButtonTemplateComponent } from '../button-template/button-template.component';
import { UtilitiesService } from '../../services/utilities.service';
import { LoadingService } from '../../services/loading.service';
import { ToastrService } from 'ngx-toastr';
import {
  eCountPendenciasCliente,
  eCountPendenciasEmpresa,
  eLoggedType,
  eWsStatus,
  egender,
  erole,
  navBarFormType,
} from '../../enums/Enums';
import { RegisterUserAccount } from '../../models/Register.account.user.data.model';
import { AccountService } from '../../services/account.service';
import { Empresa } from '../../models/Account.data.model';
import { Usuario } from '../../interfaces/security';
import { UserRolePipe } from '../../pipes/user-role.pipe';
import { AuthorizationService } from '../../services/authorization.service';

import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { SpeedDialModule } from 'primeng/speeddial';
import { MenuItem, MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import { Cliente } from '../../models/Client.data.model';
import { ClientsService } from '../../services/clients.service';
import { TimerActionComponent } from '../timer-action/timer-action.component';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { RequisicaoResgate } from '../../models/Cashback.data.model';
import { environment } from '../../../environments/environment';
import { ModalidadeCashbackPipe } from '../../pipes/modalidade-cashback.pipe';
import { AuthenticationService } from '../../services/authentication.service';
import { IApiResponse } from '../../interfaces/api-response.interface';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';
import { TipComponent } from '../tip/tip.component';
import { MyBadgeComponent } from '../my-badge/my-badge.component';
import {
  IEventMessageEntity,
  IQtdConnections,
} from '../../models/WS.data.model';
import { WebsocketService } from '../../services/websocket.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
  providers: [MessageService, WebsocketService],
  imports: [
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
    TextFieldComponent,
    SelectFieldComponent,
    ButtonTemplateComponent,
    UserRolePipe,
    SpeedDialModule,
    SidebarModule,
    TooltipModule,
    TimerActionComponent,
    ButtonModule,
    OverlayPanelModule,
    DividerModule,
    ModalidadeCashbackPipe,
    NgxTippyModule,
    TipComponent,
    MyBadgeComponent,
  ],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() isHidden = false;
  @Input() set wsConnection(value: eWsStatus|undefined) {
    this.wsConnectionStatus = value || undefined;
  };
  @Input() set qtdConnected(value: IQtdConnections|undefined) {
    this.qtdConnections = value || undefined;
  };
  @Input() set broadcast(value: IEventMessageEntity|undefined) {
    this.broadcastMessage = value || undefined;
  }
  @Input() set message(value: IEventMessageEntity|undefined) {
    this.messageFromServer = value || undefined;
  }
  @Input() set pendencias(value: eCountPendenciasEmpresa|undefined) {
    this.countPendenciasEmpresa = value || {
      qtdEmpresasAguardandoAprovacao: 0,
      qtdUsuariosAguardandoAprovacao: 0,
    };
  }
  @Output() toggleSideNav = new EventEmitter<void>();

  @ViewChild('sidebarRef') sidebarRef!: Sidebar;

  isWSConnected = false;
  wsConnectionStatus?: eWsStatus;
  
  qtdConnections?: IQtdConnections;

  broadcastMessage?: IEventMessageEntity;
  messageFromServer?: IEventMessageEntity;

  title = 'New Topvix';
  currentUser = new Usuario();
  currentCustomer = new Cliente();
  currentEmpresa = new Empresa();

  currentForm: navBarFormType = 'MY-DATA';

  public readonly tippyProps: NgxTippyProps = {
    arrow: true,
    duration: [500, 100],
    theme: 'translucent',
    animation: 'scale',
    inertia: true,
    followCursor: 'horizontal',
  };

  intervalTimeCheck = 0;

  sidebarVisible = false;

  drawerEnabled = false;
  formTitle = 'Título';
  svgIcon = 'icon-[carbon--operations-record]';

  isLoading = false;
  requiredValidator = Validators.required;

  linkToRequests?: string;
  linkToAcessoClient = environment.API_AUTH.Methods.CUSTOMER_AUTH_LOGIN;
  linkToRespondePesquisaCliente =
    environment.API_AREA_CLIENTE.Methods.ROTA_FORM_PESQUISA_CLIENTE;

  tipo_gender: egender = 'INDEFINIDO';
  tipo_role: erole = 'USER';

  currentLoggedType?: eLoggedType;

  isSidebarOpen = false;

  countPendenciasEmpresa!: eCountPendenciasEmpresa;

  countPendenciasCliente: eCountPendenciasCliente = {
    qtdRequisicaoResgateAguardandoAprovacao: 0,
    qtdPesquisaSatisfacaoLiberadaResponder: 0,
    pesquisaSatisfacaoLiberadaComCredito: false,
    qtdSorteioAberto: 0,
    isVencedorSorteio: false,
  };

  isActionActivated: boolean = true;

  requisicaoResgateAprovadaList: RequisicaoResgate[] = [];

  items: MenuItem[] = [
    {
      icon: 'pi pi-pencil',
      iconClass: 'p-speed-dial-button',
      // iconStyle: {'background-color':'blue'},
      label: 'Registrar Compra',
      command: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Add',
          detail: 'Data Added',
        });
      },
    },
    {
      icon: 'pi pi-refresh',
      label: 'Registrar Resgate',
      command: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Update',
          detail: 'Data Updated',
        });
      },
    },
    {
      icon: 'pi pi-trash',
      label: 'Novo Cliente',
      command: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Delete',
          detail: 'Data Deleted',
        });
      },
    },
  ];
  userSubscription?: Subscription;
  customerSubscription?: Subscription;
  accountSubscription?: Subscription;
  formSubscription?: Subscription;
  loadingSubscription?: Subscription;
  loggedTypeSubscription?: Subscription;
  formSenhaSubscription?: Subscription;
  niverSubscription?: Subscription;
  wsStatusSubscription?: Subscription;


  maxDateToNascimento: string;

  constructor(
    public readonly userService: UserService,
    public readonly authorizationService: AuthorizationService,
    public readonly authService: AuthenticationService,
    public readonly accountService: AccountService,
    private readonly fb: FormBuilder,
    private readonly utility: UtilitiesService,
    private readonly loadingService: LoadingService,
    private readonly toastr: ToastrService,
    private readonly messageService: MessageService,
    private readonly customerService: ClientsService,
    private readonly router: Router,
    private readonly imageCompress: NgxImageCompressService,
    private wsService: WebsocketService
  ) {
    // initFlowbite();

    this.maxDateToNascimento = this.utility.dateBr(new Date(), 'yyyy-MM-dd');

    this.wsStatusSubscription = this.wsService.isWSConnectedV2Observer().subscribe((value) => {
      console.log('WS_Socket - CONECTADO V2? (navbar):', value);
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.customerSubscription) this.customerSubscription.unsubscribe();
    if (this.accountSubscription) this.accountSubscription.unsubscribe();
    if (this.formSubscription) this.formSubscription.unsubscribe();
    if (this.formSenhaSubscription) this.formSenhaSubscription.unsubscribe();
    if (this.loadingSubscription) this.loadingSubscription.unsubscribe();
    if (this.loggedTypeSubscription) this.loggedTypeSubscription.unsubscribe();
    if (this.niverSubscription) this.niverSubscription.unsubscribe();    
  }

  formMyData = this.fb.group(
    {
      nome: [
        ' ',
        [Validators.required, CustomValidators.ValidatorCompleteName],
      ],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      cpf: ['', [CustomValidators.ValidatorCPF]],
      telefone: [''],
      celular: ['', [CustomValidators.ValidatorPhoneNumberBR]],
      genero: [this.currentUser.gender_id || 'INDEFINIDO'],
    },
    {
      validators: CustomValidators.groupValidator_TelefoneCelularIguais,
    }
  );

  get frmMyData() {
    return this.formMyData.controls;
  }

  dia_niver?: number | null;
  mes_niver?: number | null;

  formMyCustomerData = this.fb.group({
    cpf: ['', [Validators.required, CustomValidators.ValidatorCPF]],
    nome: [' ', [Validators.required, CustomValidators.ValidatorCompleteName]],
    email: ['', Validators.email],
    celular: [
      '',
      [Validators.required, CustomValidators.ValidatorPhoneNumberBR],
    ],
    genero: [this.currentUser.gender_id || 'INDEFINIDO'],
    // aniversario: ['', CustomValidators.ValidatorAniversarioValidade],
    // dia_aniversario: [this.dia_niver],
    // mes_aniversario: [this.mes_niver],
    dt_nascimento: [''],
  });

  get frmMyCustomerData() {
    return this.formMyCustomerData.controls;
  }

  formNewUser = this.fb.group({
    nome: ['', [Validators.required, CustomValidators.ValidatorCompleteName]],
    // cpf: ['', [CustomValidators.ValidatorCPF]],
    email: ['', [Validators.required, Validators.email]],
    // telefone: [''],
    // celular: [''],
    // genero: [this.tipo_gender],
    funcao_funcionario: [''],
    password: ['', [Validators.required, Validators.min(3)]],
    perfil_usuario: [this.tipo_role, Validators.required],
  });

  get frmNewUser() {
    return this.formNewUser.controls;
  }

  formChangePassword = this.fb.group(
    {
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },
    { validators: CustomValidators.groupValidator_ConfirmPassword }
  );

  get frmChPass() {
    return this.formChangePassword.controls;
  }

  formCustomerChangePassword = this.fb.group(
    {
      cpf: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },
    { validators: CustomValidators.groupValidator_ConfirmPassword }
  );

  get frmCustomerChPass() {
    return this.formCustomerChangePassword.controls;
  }

  formConfig = this.fb.group({
    qtd_meses_validade_cashback: [0, Validators.min(1)],
    pts_por_valor: [0, Validators.min(1)],
    percentual_cashback_por_vl: [0],
    vl_gasto: [0, Validators.min(0.01)],
    vl_min_resgate_cashback: [0, Validators.min(0.01)],
  });

  get frmCfg() {
    return this.formConfig.controls;
  }

  imageWidth = undefined;
  imageHeight = undefined;
  imageValid = true;

  formLogoImage = this.fb.group({
    imagem: ['', Validators.required],
    imagem_width: [0, Validators.min(1)],
    imagem_height: [0, Validators.min(1)],
    filename: ['', Validators.required],
  });

  get frmImg() {
    return this.formLogoImage.controls;
  }

  setFileData(fileData: any) {
    console.log('[ FormAccount ] fileData:', fileData);

    const { file, image } = fileData;

    const _f = file as File;
    // const _i = image as HTMLImageElement;
    // console.log('[ FormAccount ] image:', image, fileData.image);

    this.imageWidth = image.width;
    this.imageHeight = image.height;

    this.imageValid = this.frmImg.imagem.valid;

    this.formLogoImage.patchValue({
      filename: _f.name,
      imagem_width: image.width,
      imagem_height: image.height,
    });
  }

  clearImage() {
    this.formLogoImage.patchValue({
      imagem: '',
      filename: '',
      imagem_width: 0,
      imagem_height: 0,
    });
  }

  compressFile() {
    this.imageCompress
      .uploadFile()
      .then(async ({ image, orientation, fileName }) => {
        // const { width, height } = await this.getImageDimensions(image);
        // console.log('image original (WxH):', width, height);

        this.frmImg.filename.setValue(fileName, {
          emitEvent: false,
          onlySelf: true,
        });

        this.imageCompress
          .compressFile(image, orientation, 100, 100, 1200, 1200) // 100% ratio, 100% quality
          .then(async (compressedImage) => {
            const { width, height } = await this.utility.getImageDimensions(
              compressedImage
            );
            // console.log('image reduzida (WxH):', width, height);

            this.formLogoImage.patchValue(
              {
                imagem: compressedImage,
                imagem_height: height,
                imagem_width: width,
              },
              {
                emitEvent: false,
                onlySelf: true,
              }
            );
          });
      });
  }

  accounts: Empresa[] = [];
  accountList: selectFieldDataModel[] = [];

  formSelectAccount = this.fb.group({
    account_id: [0, Validators.required],
  });

  get frmSelectAccount() {
    return this.formSelectAccount.controls;
  }

  formSelectCustomerAccount = this.fb.group({
    account_id: [0, Validators.required],
  });

  get frmSelectCustomerAccount() {
    return this.formSelectCustomerAccount.controls;
  }

  ngOnInit(): void {
    this.intervalTimeCheck = environment.INTERVAL_CHECK_SECONDS;

    this.wsService.isWSConnectedObserver().subscribe((value) => {
      console.log('WS_Socket - Está conectado? (navbar):', value);
      this.isWSConnected = value;
    });


    this.userSubscription = this.userService
      .getUserObservable()
      .subscribe((user) => {
        this.currentUser = user;
        // console.log('[NavBar] - usuário:', this.currentUser);
      });

    this.customerSubscription = this.authorizationService
      .currentCustomerLoggedObserver()
      .subscribe((value) => {
        this.currentCustomer = value;
        if (value.cpf) this.frmCustomerChPass.cpf.setValue(value.cpf);
        // console.log('[NavBar] - cliente:', this.currentCustomer);
      });

    this.accountSubscription = this.accountService
      .currentEmpresaObserver()
      .subscribe((value) => {
        if (value) {
          this.currentEmpresa = value;
        }
        console.log('[NavBar] - empresa:', this.currentEmpresa);
      });

    this.formSubscription = this.formSelectAccount.valueChanges.subscribe(
      (values) => {
        console.log('[NavBar] - formSelectAccount:', values);
      }
    );

    this.formSenhaSubscription =
      this.formCustomerChangePassword.valueChanges.subscribe((values) => {
        console.log('[NavBar] - formCustomerChangePassword:', values);
      });

    this.loadingSubscription = this.loadingService.isLoading.subscribe(
      (value) => {
        this.isLoading = value;
      }
    );

    this.loggedTypeSubscription = this.authorizationService
      .currentLoggedTypeObserver()
      .subscribe((value) => {
        this.currentLoggedType = value;
      });

    // this.niverSubscription =
    //   this.frmMyCustomerData.aniversario.valueChanges.subscribe(
    //     async (value) => {
    //       // debugger;

    //       try {
    //         if (
    //           value &&
    //           value.length === 5 &&
    //           this.frmMyCustomerData.aniversario.valid
    //         ) {
    //           const _dados = value.split('/');

    //           if (_dados[0])
    //             this.frmMyCustomerData.dia_aniversario.setValue(
    //               Number.parseInt(_dados[0])
    //             );

    //           if (_dados[1])
    //             this.frmMyCustomerData.mes_aniversario.setValue(
    //               Number.parseInt(_dados[1])
    //             );
    //         }
    //       } catch (error) {}
    //     }
    //   );

    // this.drawerService.statusDrawerObserver().subscribe(value => {

    //   if (value) {

    //     if (this.currentForm === 'MY-DATA') {

    //       if (this.currentUser && this.currentUser.nome) {

    //         this.formMyData.patchValue({
    //           nome: this.currentUser.nome,
    //           username: this.currentUser.username,
    //           cpf: this.currentUser.cpf ? this.utilities.cpfFormatter(this.currentUser.cpf) : null,
    //           email: this.currentUser.email,
    //           celular: this.utilities.foneFormatter(this.currentUser?.celular),
    //           telefone: this.utilities.foneFormatter(this.currentUser.telefone),
    //           genero: this.currentUser.gender_id || 'INDEFINIDO',
    //         }, { onlySelf: false });
    //       }
    //     }

    //     if (this.currentForm === 'MY-PASSWORD') {
    //       this.formChangePassword.reset();
    //     }

    //     if (this.currentForm === 'SELECT-ACCOUNT') {
    //       this.formSelectAccount.patchValue({
    //         account_id: this.accountService.currentEmpresaLogada?.empresa_id || undefined
    //       });
    //     }

    //   }
    // });

    //já faz um verificação inicial

    // this.checkPendencias();
  }

  async selectForm(selection: navBarFormType) {
    this.sidebarVisible = true;

    this.currentForm = selection;

    if (selection === 'MY-DATA') {
      this.formTitle = 'Meus Dados';
      this.svgIcon = 'icon-[carbon--user-profile]';

      if (this.currentUser && this.currentUser.nome) {
        this.formMyData.patchValue(
          {
            nome: this.currentUser.nome,
            username: this.currentUser.username,
            cpf: this.currentUser.cpf
              ? this.utility.cpfFormatter(this.currentUser.cpf)
              : null,
            email: this.currentUser.email,
            celular: this.utility.foneFormatter(this.currentUser?.celular),
            telefone: this.utility.foneFormatter(this.currentUser.telefone),
            genero: this.currentUser.gender_id || 'INDEFINIDO',
          },
          { onlySelf: false }
        );
      }
    }

    if (selection === 'MY-CUSTOMER-DATA') {
      this.formTitle = 'Meus Dados';
      this.svgIcon = 'icon-[carbon--user-profile]';

      if (this.currentCustomer && this.currentCustomer.nome) {
        this.formMyCustomerData.patchValue(
          {
            nome: this.currentCustomer.nome,
            cpf: this.currentCustomer.cpf
              ? this.utility.cpfFormatter(this.currentCustomer.cpf)
              : null,
            email: this.currentCustomer.email,
            celular: this.utility.foneFormatter(this.currentCustomer?.celular),
            dt_nascimento: this.utility.formatDateToDateFormBr(
              this.currentCustomer?.dt_nascimento
            ),
            genero: this.currentCustomer.sexo || 'INDEFINIDO',
          },
          { onlySelf: false }
        );
      }
    }

    if (selection === 'NEW-USER') {
      this.formTitle = 'Novo Usuário';
      this.svgIcon = 'icon-[carbon--user-follow]';
    }

    if (selection === 'MY-PASSWORD') {
      this.formTitle = 'Trocar minha senha';
      this.svgIcon = 'icon-[carbon--user-sponsor]';

      this.frmChPass.password.reset();
      this.frmChPass.confirm_password.reset();
    }

    if (selection === 'MY-CUSTOMER-PASSWORD') {
      this.formTitle = 'Trocar minha senha';
      this.svgIcon = 'icon-[carbon--user-sponsor]';

      this.frmCustomerChPass.password.reset();
      this.frmCustomerChPass.confirm_password.reset();
    }

    if (selection === 'SELECT-ACCOUNT') {
      this.formTitle = 'Selecionar empresa';
      this.svgIcon = 'icon-[mdi--building]';

      this.accountList = [];
      const accounts = await this.accountService.getAllAccounts(
        undefined,
        undefined,
        'ATIVO'
      );

      if (accounts && accounts.length) {
        accounts.map((item) => {
          this.accountList.push({
            code: item.empresa_id,
            name: item.nome_fantasia || item.razao_social || 'indefinido',
          });
        });

        if (this.accountService.currentEmpresaLogada?.empresa_id)
          this.formSelectAccount.patchValue({
            account_id: this.accountService.currentEmpresaLogada?.empresa_id,
          });
      } else {
        this.toastr.warning('Nenhuma empresa ativa encontrada!');
      }
    }

    if (selection === 'SELECT-CUSTOMER-ACCOUNT') {
      this.formTitle = 'Selecionar empresa';
      this.svgIcon = 'icon-[mdi--building]';

      this.accountList = [];

      if (
        this.currentCustomer.EmpresasPossuemCliente &&
        this.currentCustomer.EmpresasPossuemCliente.length
      ) {
        this.currentCustomer.EmpresasPossuemCliente.map((item) => {
          const { Empresa } = item;

          if (
            Empresa &&
            Empresa.empresa_id &&
            (Empresa.nome_fantasia || Empresa.razao_social)
          )
            this.accountList.push({
              code: Empresa.empresa_id,
              name:
                Empresa?.nome_fantasia || Empresa?.razao_social || 'indefinido',
            });
        });

        if (this.accountService.currentEmpresaLogada?.empresa_id)
          this.formSelectAccount.patchValue({
            account_id: this.accountService.currentEmpresaLogada?.empresa_id,
          });
      } else {
        this.toastr.warning('Nenhuma empresa ativa encontrada!');
      }
    }

    if (selection === 'CONFIG') {
      this.formTitle = 'Parâmetros de pontuação';
      this.svgIcon = 'icon-[mdi--cash-edit]';

      this.formConfig.patchValue({
        qtd_meses_validade_cashback:
          this.accountService.currentEmpresaLogada?.qtd_meses_validade_cashback,
        pts_por_valor: this.accountService.currentEmpresaLogada?.pts_por_valor,
        percentual_cashback_por_vl: Number.parseInt(
          this.accountService.currentEmpresaLogada?.percentual_cashback_por_vl?.toString() ||
            '0'
        ),
        vl_gasto: this.accountService.currentEmpresaLogada?.vl_gasto,
        vl_min_resgate_cashback:
          this.accountService.currentEmpresaLogada?.vl_min_resgate_cashback,
      });
    }

    if (selection === 'MY-LOGO') {
      this.formTitle = 'Logotipo da empresa';
      this.svgIcon = 'icon-[mdi--image-edit-outline]';

      this.formLogoImage.patchValue({
        filename: this.accountService.currentEmpresaLogada?.filename,
        imagem: this.accountService.currentEmpresaLogada?.imagem,
        imagem_height: this.accountService.currentEmpresaLogada?.imagem_height,
        imagem_width: this.accountService.currentEmpresaLogada?.imagem_width,
      });
    }
  }

  toggleSideNavAction() {
    this.toggleSideNav.emit();
  }

  closeCallback(e: any): void {
    console.log('[ NavBar ] closeCallback:', e);

    this.sidebarRef.close(e);
  }

  closeSideBar() {
    this.isSidebarOpen = false;
  }

  onToggleNavBar(status: boolean) {
    console.log('[ NavBar ] open?', status);
  }

  async submitData() {
    try {
      if (this.currentForm === 'MY-DATA') {
        const _data: Usuario = {
          userid: this.currentUser.userid,
          nome: this.frmMyData.nome.value!,
          username: this.frmMyData.username.value!,
          email: this.frmMyData.email.value!,
          celular: this.utility.foneClearFormat(this.frmMyData.celular.value),
          cpf: this.utility.cpfClearFormat(this.frmMyData.cpf.value),
          telefone: this.utility.foneClearFormat(this.frmMyData.telefone.value),
          gender_id: this.frmMyData.genero.value || 'INDEFINIDO',
          user_role: this.currentUser.user_role,
          status: 'ATIVO',
          empresa_usuario_fk: this.currentEmpresa.empresa_id,
          usuario_fk: this.userService.currentUser.userid,
        };
        const result = await this.userService.updateUserData(_data);

        if (result) {
          this.toastr.success(
            'Dados atualizados com sucesso!',
            'Atualização de dados'
          );

          this.formMyData.reset();
        }
      }

      if (this.currentForm === 'MY-CUSTOMER-DATA') {
        if (this.currentCustomer.cliente_id) {
          const _data: Cliente = {
            nome: this.frmMyCustomerData.nome.value!,
            email: this.frmMyCustomerData.email.value!,
            celular: this.utility.foneClearFormat(
              this.frmMyCustomerData.celular.value
            ),
            cpf: this.utility.cpfClearFormat(this.frmMyCustomerData.cpf.value),
            dt_nascimento: this.utility.dateStringToDatabase(
              this.frmMyCustomerData?.dt_nascimento?.value,
              'keep'
            ),
            sexo: this.frmMyCustomerData.genero.value || 'INDEFINIDO',
          };

          //
          // 21/set/2024: SEM A INFORMAÇÃO DO USUÁRIO QUE EDITOU O REGISTRO, PORQUE AQUI É REALIZADO PELO PRÓPRIO CLIENTE
          //
          const result = await this.customerService.updateClient(
            this.currentCustomer.cliente_id,
            _data
          );

          if (result) {
            this.toastr.success('Dados atualizados com sucesso!');

            this.authorizationService.setCurrentCustomerLogged(result);
            this.authorizationService.setCustomerProfileToLocalHistory(result);
          }
        }
      }

      if (this.currentForm === 'NEW-USER') {
        console.log(
          '[ New User ] empresa:',
          this.accountService.currentEmpresaLogada
        );

        const _data: RegisterUserAccount = {
          nome: this.frmNewUser.nome.value!,
          username: this.frmNewUser.nome.value?.split(' ')[0],
          email: this.frmNewUser.email.value!,
          password: this.frmNewUser.password.value || undefined,
          // celular: this.utility.foneClearFormat(this.frmNewUser.celular.value),
          // cpf: this.utility.cpfClearFormat(this.frmNewUser.cpf.value),
          // telefone: this.utility.foneClearFormat(
          //   this.frmNewUser.telefone.value
          // ),
          // gender_id: this.frmNewUser.genero.value || 'INDEFINIDO',
          user_role: this.frmNewUser.perfil_usuario.value || 'USER',
          funcao_funcionario:
            this.frmNewUser.funcao_funcionario.value || undefined,

          status: 'ATIVO',
          account_id: this.accountService.currentEmpresaLogada?.empresa_id,

          usuario_fk: this.userService.currentUser.userid,
          empresa_usuario_fk: this.currentEmpresa.empresa_id,
        };

        console.log('[ New User ] dados:', _data);

        const result = await this.userService.createUserAccount(_data);

        if (result) {
          this.toastr.success(
            'Usuário cadastrado com sucesso!',
            'Novo usuário'
          );

          this.formNewUser.reset();
        }
      }

      if (this.currentForm === 'MY-PASSWORD') {
        if (this.currentUser.email && this.frmChPass.password.value) {
          const result = await this.userService.updateUserPassword(
            this.currentUser.email,
            this.frmChPass.password.value
          );

          if (result) {
            this.toastr.success(
              'Senha atualizada com sucesso!',
              'Troca de senha'
            );

            this.formChangePassword.reset();
          }
        }
      }

      if (this.currentForm === 'MY-CUSTOMER-PASSWORD') {
        if (this.currentCustomer.cpf && this.frmCustomerChPass.password.value) {
          const _dados: Cliente = {
            cpf: this.currentCustomer.cpf,
            password: this.frmCustomerChPass.password.value,
            token_validacao: this.currentCustomer.token_validacao,
          };

          const result = await this.customerService.resetSenhaCliente(
            this.currentCustomer.cpf,
            _dados
          );

          if (result) {
            this.toastr.success('Senha atualizada com sucesso!');

            this.frmCustomerChPass.password.reset();
            this.frmCustomerChPass.confirm_password.reset();
          }
        }
      }

      if (this.currentForm === 'SELECT-ACCOUNT') {
        if (this.frmSelectAccount.account_id.value) {
          // const value: selectFieldDataModel = this.frmSelectAccount.account_id.value as any;
          // console.log('[ NavBar ] submit - frmSelectAccount', value);

          const _account = await this.accountService.getAccountById(
            this.frmSelectAccount.account_id.value
          );

          if (_account) {
            this.accountService.setAccountToLocalHistory(_account);
            this.accountService.setCurrentEmpresa(_account);

            this.toastr.success(
              `Empresa ${_account.nome_fantasia} selecionada com sucesso!`,
              'Seleção de empresa'
            );
          }
        }
      }

      if (this.currentForm === 'SELECT-CUSTOMER-ACCOUNT') {
        if (
          this.currentCustomer.EmpresasPossuemCliente &&
          this.currentCustomer.EmpresasPossuemCliente.length > 1
        ) {
          if (
            this.frmSelectAccount.account_id.value &&
            this.frmSelectAccount.account_id.valid
          ) {
            const _account = this.currentCustomer.EmpresasPossuemCliente.find(
              (value) =>
                value.empresa_fk ===
                this.frmSelectCustomerAccount.account_id.value
            )?.Empresa;

            if (_account && _account?.nome_fantasia) {
              this.accountService.setAccountToLocalHistory(_account);
              this.accountService.setCurrentEmpresa(_account);

              this.toastr.success(
                `Empresa ${_account.nome_fantasia} selecionada com sucesso!`,
                'Seleção de empresa'
              );
            }
          }
        }
      }

      if (this.currentForm === 'CONFIG') {
        if (this.accountService.currentEmpresaLogada?.empresa_id) {
          const _empresa: Empresa = {
            qtd_meses_validade_cashback:
              this.frmCfg.qtd_meses_validade_cashback.value!,
            pts_por_valor: Number.parseInt(
              this.frmCfg.pts_por_valor.value!.toString()
            ),
            percentual_cashback_por_vl: Number.parseInt(
              this.frmCfg.percentual_cashback_por_vl.value?.toString() || '0'
            ),
            vl_gasto: this.frmCfg.vl_gasto.value!,
            vl_min_resgate_cashback: this.frmCfg.vl_min_resgate_cashback.value!,
            status: this.accountService.currentEmpresaLogada.status,
            is_cashback_categoria_ativo:
              this.accountService.currentEmpresaLogada
                .is_cashback_categoria_ativo,
            empresa_usuario_fk: this.currentEmpresa.empresa_id,
            usuario_fk: this.userService.currentUser.userid,
          };
          const result = await this.accountService.updateAccount(
            this.accountService.currentEmpresaLogada.empresa_id,
            _empresa
          );

          if (result) {
            this.toastr.success(
              'Parâmetros de pontuação atualizados com sucesso!'
            );

            this.accountService.setAccountToLocalHistory(result);
            this.accountService.setCurrentEmpresa(result);
          }
        }
      }

      if (this.currentForm === 'MY-LOGO') {
        if (this.accountService.currentEmpresaLogada?.empresa_id) {
          const _empresa: Empresa = {
            filename: this.frmImg.filename.value!,
            imagem: this.frmImg.imagem.value!,
            imagem_height: this.frmImg.imagem_height.value!,
            imagem_width: this.frmImg.imagem_width.value!,
            status: this.accountService.currentEmpresaLogada.status,
            is_cashback_categoria_ativo:
              this.accountService.currentEmpresaLogada
                .is_cashback_categoria_ativo,
            empresa_usuario_fk: this.currentEmpresa.empresa_id,
            usuario_fk: this.userService.currentUser.userid,
          };
          const result = await this.accountService.updateAccount(
            this.accountService.currentEmpresaLogada.empresa_id,
            _empresa
          );

          if (result) {
            this.toastr.success('Logo da empresa atualizado com sucesso!');

            this.accountService.setAccountToLocalHistory(result);
            this.accountService.setCurrentEmpresa(result);
          }
        }
      }

      this.sidebarVisible = false;
    } catch (error) {
      this.toastr.error(
        `Falha ao atualizar dados. Motivo: ${error}`,
        'Atualização de dados'
      );
    }
  }

  formatNiver(dia?: number, mes?: number) {
    if (!dia || !mes) return undefined;
    return this.utility.formatNiverToForm(dia, mes);
  }

  async checkPendencias() {
    this.isActionActivated = false;

    // debugger;

    try {
      if (
        this.currentLoggedType === 'customer' &&
        this.currentCustomer.cliente_id &&
        this.currentEmpresa.empresa_id
      ) {
        this.countPendenciasCliente =
          await this.accountService.checkPendenciasAreaCliente(
            this.currentEmpresa.empresa_id,
            this.currentCustomer.cliente_id
          );
        // this.qtdRequisicaoResgateAguardandoAprovacao =
        //   await this.accountService.checkRequisicaoResgateAguardandoAprovacao(
        //     this.currentCustomer.cliente_id
        //   );

        const lastRequestData = await this.accountService
          .getLastRequisicaoResgateID(this.currentCustomer.cliente_id)
          .catch((error: IApiResponse) => {
            console.log('[ NavBar ] Última requisição resgate - Erro:', error);
          });
        console.log('[ NavBar ] última requisição resgate:', lastRequestData);

        if (lastRequestData && lastRequestData.requisicao_id) {
          switch (lastRequestData.status) {
            case 'PENDING_CLIENT': //ação do estabelecimento
              //coloca a requisição na pendência de aprovação e substitui qualquer outra que estava gravada
              this.linkToRequests = `${environment.API_AREA_CLIENTE.Methods.PATH_APROVE_REQUEST}/${lastRequestData.requisicao_id}`;

              const _request_atual = this.authService.getLastRequestResgate();

              if (
                _request_atual &&
                _request_atual.requisicao_id !== lastRequestData.requisicao_id
              ) {
                //tem request nova e a que estava salva não é mais válida
                this.authService.setLastRequestResgate(lastRequestData);
                //navega para essa nova requisição
                this.router.navigateByUrl(this.linkToRequests);
              }

              //não tinha registro anterior gravado localmente
              if (!_request_atual) {
                //tem request nova e a que estava salva não é mais válida
                this.authService.setLastRequestResgate(lastRequestData);
                //navega para essa nova requisição
                this.router.navigateByUrl(this.linkToRequests);
              }

              break;
            default:
              this.authService.setLastRequestResgate(lastRequestData);
              break;
          }
        }
      }
    } catch (error) {
      this.countPendenciasCliente = {
        qtdRequisicaoResgateAguardandoAprovacao: 0,
        pesquisaSatisfacaoLiberadaComCredito: false,
        qtdPesquisaSatisfacaoLiberadaResponder: 0,
        qtdSorteioAberto: 0,
        isVencedorSorteio: false,
      };
    }

    this.isActionActivated = true;
  }

  clearMessage(tipo: 'broadcast' | 'message') {
    if (tipo === 'broadcast') {
      this.broadcastMessage = undefined;
    } else {
      this.messageFromServer = undefined;
    }
  }
}
