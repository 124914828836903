@if(currentLoggedType === 'user') {
<div
  class="{{
  currentModalDeleteConfirmationStatus==='openned'?'pointer-events-none backdrop-blur':'pointer-events-auto backdrop-blur-none'}}"
>  
  <div
    class="
    {{currentModalDeleteConfirmationStatus==='openned'?'pointer-events-none backdrop-blur bg-topvix-button_disabled/25 text-topvix-button_disabled/5':'pointer-events-auto backdrop-blur-none bg-topvix-bg'}}
     transition-all duration-150 ease-linear relative z-10 top-0 h-screen shadow-xl overflow-y-auto"
    (mouseenter)="toggleCollapsed()"
    (mouseleave)="toggleCollapsed()"
    [ngClass]="collapsed ? 'w-64' : 'w-16'"
  >
    <div class="flex flex-row align-middle justify-evenly w-full p-3">
      <button
        @fadeInOut
        class="text-center w-12 min-w-12 p-1 font-medium cursor-pointer border-0"
      >
        <span
          class="text-2xl"
          [ngClass]="
            collapsed
              ? 'icon-[carbon--right-panel-open]'
              : 'icon-[carbon--right-panel-close]'
          "
        ></span>
      </button>
      <div *ngIf="collapsed" class="m-6 text-2xl font-bold text-topvix-button">
        Topvix
      </div>
      <button @rotate (click)="pinSideNav()" *ngIf="collapsed">
        <span *ngIf="!pinned" class="icon-[carbon--pin] text-2xl"></span>
        <span
          *ngIf="pinned"
          class="icon-[carbon--pin-filled] text-2xl animate-pulse text-red-500 rounded-full p-2 backdrop-blur-2xl"
        ></span>
      </button>
    </div>

    <ul
      class="list-none p-3 m-0 flex flex-col items-center justify-evenly h-[calc(100% - 3.65rem)] cursor-pointer"
    >
      <li *ngFor="let data of navData" class="w-full mb-2 rounded-lg">
        <ng-container *ngIf="getItemVisibility(data)">
          <a
            *ngIf="data.items && data.items.length > 0"
            routerLinkActive="bg-topvix-secondary text-white"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="handleClick(data)"
            [ngClass]="getActiveClass(data)"
            class="flex items-center h-12 rounded text-topvix-button hover:bg-topvix-button hover:text-topvix-primary transition-colors duration-75 ease-linear"
          >
            <span
              class="{{
                data.icon
              }} text-2xl min-w-8 w-8 mx-auto duration-75 hover:translate-x-2"
            ></span>
            <span *ngIf="collapsed" class="flex-grow ml-6" @fadeInOut>{{
              data.label
            }}</span>

            <span
              *ngIf="data.items && collapsed"
              class="pr-2 text-2xl w-8 min-w-8 mx-auto text-center"
              [ngClass]="
                !data.expanded
                  ? 'icon-[carbon--chevron-right]'
                  : 'icon-[carbon--chevron-down]'
              "
            ></span>
          </a>

          <a
            *ngIf="!data.items || (data.items && data.items.length === 0)"
            [routerLink]="[data.routerLink]"
            routerLinkActive="bg-topvix-secondary text-white"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="shrinkItems(data)"
            class="flex items-center h-12 rounded transition-all duration-75 ease-linear text-topvix-button hover:bg-topvix-button hover:text-topvix-primary"
          >
            <span
              class="{{
                data.icon
              }} text-2xl min-w-8 w-8 mx-auto text-center duration-75 hover:translate-x-2"
            ></span>
            <span *ngIf="collapsed" class="flex-grow ml-6" @fadeInOut>{{
              data.label
            }}</span>
          </a>

          <div *ngIf="collapsed && data.items && data.items.length > 0">
            <sublevel-menu
              [data]="data"
              [multiple]="multiple"
              [expanded]="data.expanded"
              [collapsed]="collapsed"
            ></sublevel-menu>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</div>
}