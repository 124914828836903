<nav
  class="{{
    isHidden ? 'hidden' : ''
  }} z-50 w-full border-b shadow bg-topvix-secondary text-topvix-primary  "
>
  <!-- LINK RÁPIDO FLUTUANTE -->
  <!-- faltando implementar os atalhos e melhorar o layout -->
  <!-- <div
    class="z-[1000] fixed bottom-1/4 right-10 w-12 h-10 transition duration-150">
    <p-speedDial
      [model]="items"
      direction="left"
      buttonClassName="bg-gray-200/50 text-topvix-secondary"
      type="semi-circle"></p-speedDial>
  </div> -->

  <div class="w-full py-3">
    <div class="w-full px-2 flex items-center justify-between">

      <!-- LOGOTIPO E NOME EMPRESA -->
      <div class="flex items-center justify-start gap-2 px-2">
        <button
          *ngIf="currentLoggedType === 'user'"
          type="button"
          (click)="toggleSideNavAction()"
          class="md:hidden"
        >
          <span class="icon-[carbon--menu] h-6 w-6 text-gray-100"></span>
        </button>

        <div class="flex justify-center items-center gap-3 group">
          <img
            [src]="
              currentEmpresa.imagem || '../../../assets/images/logo-sistema.png'
            "
            class="object-scale-down h-10 w-10 group-hover:scale-110"
            alt="Logo empresa"
          />
          <span
            class="md:block hidden self-center font-semibold text-2xl text-nowrap"
            >{{
              currentEmpresa.nome_fantasia || "Empresa não selecionada"
            }}</span
          >
        </div>
      </div>

      <div
        class="grow flex flex-col md:flex-row justify-end items-center gap-2"
      >
        <!-- NOTIFICAÇÕES -->

        <div          
          class="grow flex-col justify-center items-center gap-2"
        >
          <div
            *ngIf="broadcastMessage?.message"
            class="relative w-fit text-center text-topvix-bg font-medium text-xl rounded-md border shadow-md px-3 py-1 mx-auto bg-topvix-button cursor-pointer group"
          >
            <div>
              {{ broadcastMessage?.message || "" }}
            </div> 
            <span 
              (click)="clearMessage('broadcast')"
              class="icon-[mdi--close-circle-outline] w-5 h-5 fill-red-500 bg-red-400/50 group-hover:bg-red-500 p-1 rounded-full absolute -top-2 -end-2 hover:scale-110">
            </span>
          </div>

          <div
            *ngIf="messageFromServer?.message"            
            class="w-fit text-center text-topvix-bg font-medium text-xl rounded-md border shadow-md px-3 py-1 mx-auto bg-topvix-button cursor-pointer group"
          >            
            <div>
              {{ messageFromServer?.message || "" }}
            </div> 
            <span 
              (click)="clearMessage('message')"
              class="icon-[mdi--close-circle-outline] w-5 h-5 fill-red-500 bg-red-400/50 group-hover:bg-red-500 p-1 rounded-full absolute -top-2 -end-2 hover:scale-110">
            </span>
          </div>
        </div>


        <my-badge
          class="hidden md:block"
          [label]="wsConnectionStatus && wsConnectionStatus === 'CONNECTED' ? 'online' : 'offline'"
          [value]="qtdConnections?.qtd_connected || 0"
          [type]="wsConnectionStatus && wsConnectionStatus === 'CONNECTED' ? 'success' : 'warning'"
          [icon]="
            wsConnectionStatus && wsConnectionStatus === 'CONNECTED'
              ? 'icon-[mdi--checkbox-blank-circle] text-green-500'
              : 'icon-[mdi--checkbox-blank-circle-outline] text-orange-900'
          "
        ></my-badge>

        <my-badge
          class="hidden md:block"
          label="logados"
          [value]="qtdConnections?.qtd_logged || 0"
          [type]="qtdConnections?.qtd_logged ? 'success' : 'warning'"
          [icon]="
            wsConnectionStatus && wsConnectionStatus === 'CONNECTED'
              ? 'icon-[mdi--checkbox-blank-circle] text-green-500'
              : 'icon-[mdi--checkbox-blank-circle-outline] text-orange-900'
          "
        ></my-badge>

        @if((countPendenciasEmpresa.qtdEmpresasAguardandoAprovacao) ||
        (countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao) ||
        countPendenciasCliente.qtdRequisicaoResgateAguardandoAprovacao ||
        countPendenciasCliente.qtdPesquisaSatisfacaoLiberadaResponder ||
        requisicaoResgateAprovadaList.length) {

        <!-- PANEL DOS ALERTAS -->
        <div
          (click)="op.toggle($event)"
          (mouseenter)="op.toggle($event)"
          class="relative duration-150 hover:duration-300 text-red-500 cursor-pointer group"
        >
          <span
            class="icon-[carbon--notification] w-6 h-6 group-hover:scale-110"
          >
          </span>
          <div
            class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold animate-ping text-white bg-red-500 border-2 border-white rounded-full -top-3 -end-4"
          >
            {{
              countPendenciasEmpresa.qtdEmpresasAguardandoAprovacao +
                countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao +
                countPendenciasCliente.qtdRequisicaoResgateAguardandoAprovacao +
                countPendenciasCliente.qtdPesquisaSatisfacaoLiberadaResponder
            }}
          </div>
        </div>

        <p-overlayPanel #op>
          <div class="w-full px-3 py-2">
            <div class="w-full flex items-center justify-between border-b mb-4">
              <span
                class="icon-[carbon--notification] text-2xl animate-bounce"
              ></span>
              <div class="text-xl text-right">Notificações</div>
            </div>

            <!-- OPÇÕES FIXAS -->
            <ul class="flex flex-col justify-start items-center space-y-3">
              <li
                *ngIf="
                  currentLoggedType === 'user' &&
                  currentUser.user_role === 'ADMIN' &&
                  countPendenciasEmpresa?.qtdEmpresasAguardandoAprovacao
                "
                class="hover:font-bold"
              >
                <a
                  routerLink="Administração/Liberar Empresas"
                  class="flex flex-row justify-evenly items-center"
                >
                  <div class="relative">
                    <span
                      class="icon-[mdi--house-alert-outline] text-red-500 text-2xl"
                    ></span>
                    <div
                      class="absolute -top-3 -end-3 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-topvix-secondary bg-topvix-bg border-2 border-topvix-button rounded-full"
                    >
                      {{
                        countPendenciasEmpresa.qtdEmpresasAguardandoAprovacao
                      }}
                    </div>
                  </div>
                  <span class="ml-3 text-sm"
                    >Empresas aguardando liberação</span
                  >
                </a>
              </li>

              <li
                *ngIf="
                  currentLoggedType === 'user' &&
                  currentUser.user_role === 'ADMIN' &&
                  countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao
                "
                class="hover:font-bold"
              >
                <a
                  routerLink="Administração/Liberar Usuários"
                  class="flex flex-row justify-evenly items-center"
                >
                  <div class="relative">
                    <span
                      class="icon-[mdi--user-alert-outline] text-red-500 text-2xl"
                    ></span>
                    <div
                      class="absolute -top-3 -end-3 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-topvix-secondary bg-topvix-bg border-2 border-topvix-button rounded-full"
                    >
                      {{
                        countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao
                      }}
                    </div>
                  </div>
                  <span class="ml-3 text-sm"
                    >Usuários aguardando liberação</span
                  >
                </a>
              </li>

              <li
                *ngIf="
                  currentLoggedType === 'user' &&
                  currentUser.user_role === 'MANAGER' &&
                  countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao
                "
                class="hover:font-bold"
              >
                <a
                  routerLink="Administração/Liberar Usuários"
                  class="flex flex-row justify-evenly items-center"
                >
                  <div class="relative">
                    <span
                      class="icon-[mdi--user-alert-outline] text-red-500 text-2xl"
                    ></span>
                    <div
                      class="absolute -top-3 -end-3 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-topvix-secondary bg-topvix-bg border-2 border-topvix-button rounded-full"
                    >
                      {{
                        countPendenciasEmpresa.qtdUsuariosAguardandoAprovacao
                      }}
                    </div>
                  </div>
                  <span class="ml-3 text-sm"
                    >Usuários aguardando liberação</span
                  >
                </a>
              </li>

              <!-- Área Cliente: PEDIDO DE RESGATE PENDENTE -->
              <li
                *ngIf="
                  currentLoggedType === 'customer' &&
                  countPendenciasCliente.qtdRequisicaoResgateAguardandoAprovacao &&
                  linkToRequests
                "
                class="hover:font-bold"
              >
                <a
                  [routerLink]="linkToRequests"
                  class="flex flex-row justify-evenly items-center"
                >
                  <div class="relative">
                    <span
                      class="icon-[mdi--gift-open-outline] text-red-500 text-2xl"
                    ></span>
                    <div
                      class="absolute -top-3 -end-3 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-topvix-secondary bg-topvix-bg border-2 border-topvix-button rounded-full"
                    >
                      {{
                        countPendenciasCliente.qtdRequisicaoResgateAguardandoAprovacao
                      }}
                    </div>
                  </div>
                  <span class="ml-3 text-sm"
                    >Requisição de resgate aguardando</span
                  >
                </a>
              </li>

              <!-- Área Cliente: PESQUISA LIBERADA -->
              <li
                *ngIf="
                  currentLoggedType === 'customer' &&
                  countPendenciasCliente.qtdPesquisaSatisfacaoLiberadaResponder &&
                  linkToRespondePesquisaCliente
                "
                class="hover:font-bold"
              >
                <a
                  [routerLink]="linkToRespondePesquisaCliente"
                  class="flex flex-row justify-evenly items-center"
                >
                  <div class="relative">
                    <span
                      class="icon-[mdi--gift-open-outline] text-red-500 text-2xl"
                    ></span>
                    <div
                      class="absolute -top-3 -end-3 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-topvix-secondary bg-topvix-bg border-2 border-topvix-button rounded-full"
                    >
                      {{
                        countPendenciasCliente.qtdPesquisaSatisfacaoLiberadaResponder
                      }}
                    </div>
                  </div>
                  <span class="ml-3 text-sm"
                    >Responda a pesquisa de satisfação<span
                      class="mx-1 animate-ping delay-0 hover:animate-none"
                      *ngIf="
                        countPendenciasCliente.pesquisaSatisfacaoLiberadaComCredito
                      "
                      >e GANHE
                      {{
                        currentEmpresa.modalidade_cashback
                          | modalidadeCashback : true
                      }}</span
                    ></span
                  >
                </a>
              </li>
            </ul>

            <!-- CLIENTES QUE APROVARAM A REQUISIÇÃO DE RESGATE -->
            <ul
              *ngIf="
                currentLoggedType === 'user' &&
                requisicaoResgateAprovadaList &&
                requisicaoResgateAprovadaList.length
              "
              class="flex flex-col justify-start items-center space-y-3"
            >
              Requisição de resgate aprovada
              <li>
                <div>Cliente</div>
              </li>
            </ul>
          </div>
        </p-overlayPanel>

        }

        <!-- OVERLAY DAS OPÇÕES DE MENU -->

        <div>
          <button
            type="button"
            (click)="menuOp.toggle($event)"
            class="
              flex 
              justify-evenly 
              items-center 
              gap-2 
              transition-all
              duration-150
              hover:animate-pulse 
              bg-gradient-to-r from-gray-300/5 to-gray-200/10 
              hover:from-gray-200/10 hover:to-gray-300/5 
              text-topvix-primary/50 
              hover:text-topvix-primary 
              hover:border-topvix-bg 
              hover:bg-topvix-bg/5 
              hover:ring-1 
              focus:outline-none 
              rounded-lg 
              border-topvix-button
              shadow 
              text-xs
              font-semibold
              py-1.5 
              px-2"
          >
            <span class="icon-[carbon--user-filled] h-5 w-5"></span>
            <span class="text-center text-nowrap">Minha conta</span>
          </button>
        </div>

        <p-overlayPanel #menuOp class="p-0" styleClass="p-0">
          <div class="z-50 text-base list-none bg-white p-0 rounded-md shadow">
            <div class="bg-topvix-bg rounded-t-md p-2">
              <div
                class="flex justify-between items-center gap-1 text-sm mb-1 text-gray-900"
                role="none"
              >
                <!-- PONTO E O NOME -->
                <div class="flex gap-2 justify-start items-center me-3">
                  <span
                    class="icon-[mdi--circle-medium] text-4xl text-teal-700 px-0"
                  ></span>

                  <span
                    *ngIf="currentLoggedType === 'user'"
                    class="text-sm font-medium text-gray-900"
                    >Olá, {{ userService.currentUser.username }}!</span
                  >
                  <span
                    *ngIf="currentLoggedType === 'customer'"
                    class="text-sm font-medium text-gray-900"
                    >Olá, {{ currentCustomer.nome!.split(" ")[0] }}!</span
                  >
                </div>

                <!-- PERFIL DO LOGADO -->
                <div>
                  @if(currentLoggedType === 'user') {
                  @if(authorizationService.currentUserProfile.user_account_role)
                  {
                  <tip
                    type="normal"
                    message="{{
                      authorizationService.currentUserProfile.user_account_role
                        | userRole
                    }}"
                  ></tip>
                  <!-- <div
                    class="font-thin text-topvix-button px-2 py-0.5 rounded-xl border"
                  >
                    {{
                      authorizationService.currentUserProfile.user_account_role
                        | userRole
                    }}
                  </div> -->

                  } @else if(userService.currentUser.user_role) {
                  <tip
                    type="normal"
                    message="{{ userService.currentUser.user_role | userRole }}"
                  ></tip>
                  <!-- <div
                    class="font-thin text-topvix-button px-2 py-0.5 rounded-xl border"
                  >
                    {{ userService.currentUser.user_role | userRole }}
                  </div> -->
                  } @else {
                  <tip type="error" message="Perfil não identificado"></tip>
                  <!-- <div
                    class="font-thin text-topvix-button px-2 py-0.5 rounded-xl border"
                  >
                    Perfil não identificado
                  </div> -->
                  } } @if(currentLoggedType === 'customer') {
                  <tip type="normal" message="Cliente"></tip>
                  <!-- <div
                    class="font-thin text-topvix-button px-2 py-0.5 rounded-xl border"
                  >
                    Cliente
                  </div> -->
                  }
                </div>
              </div>

              <div
                *ngIf="currentLoggedType === 'user'"
                class="text-sm font-medium text-gray-900 truncate"
                role="none"
              >
                {{ currentUser.email || "" }}
              </div>

              <div
                *ngIf="currentLoggedType === 'customer'"
                class="text-sm font-medium text-gray-900 truncate"
                role="none"
              >
                {{ currentCustomer.email || "" }}
              </div>
            </div>

            <!-- MENU OPÇÕES - USUÁRIO ADMIN/EMPRESA -->
            <ul *ngIf="currentLoggedType === 'user'" class="py-1" role="none">
              <li>
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('MY-DATA')"
                  role="menuitem"
                  >Meus dados</a
                >
              </li>
              <li
                *ngIf="
                  authorizationService.currentUserProfile.user_account_role !==
                    'USER' && currentEmpresa.empresa_id
                "
              >
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('NEW-USER')"
                  role="menuitem"
                  >Incluir usuário</a
                >
              </li>
              <li *ngIf="userService.currentUser.user_role === 'ADMIN'">
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('SELECT-ACCOUNT')"
                  role="menuitem"
                  >Selecionar empresa</a
                >
              </li>
              <!-- <li *ngIf="userService.currentUser.user_role !== 'USER'">
              <a
                class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100   "
                (click)="selectForm('CONFIG')"
                role="menuitem"
                >Parâmetros pontuação</a
              >
            </li> -->
              <li
                *ngIf="
                  userService.currentUser.user_role !== 'USER' &&
                  currentEmpresa.empresa_id
                "
              >
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('MY-LOGO')"
                  role="menuitem"
                  >Logotipo da empresa</a
                >
              </li>
              <li>
                <a
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('MY-PASSWORD')"
                  role="menuitem"
                  >Trocar minha senha</a
                >
              </li>
            </ul>

            <!-- MENU OPÇÕES - CLIENTE -->
            <ul
              *ngIf="currentLoggedType === 'customer'"
              class="py-1"
              role="none"
            >
              <li>
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('MY-CUSTOMER-DATA')"
                  role="menuitem"
                  >Meus dados</a
                >
              </li>

              <li
                *ngIf="
                  currentCustomer.EmpresasPossuemCliente &&
                  currentCustomer.EmpresasPossuemCliente.length &&
                  accountList.length
                "
              >
                <a
                  class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  (click)="selectForm('SELECT-CUSTOMER-ACCOUNT')"
                  role="menuitem"
                  >Selecionar outra empresa</a
                >
              </li>

              <!-- <li>
                <a
                  class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100   "
                  (click)="selectForm('MY-CUSTOMER-PASSWORD')"
                  role="menuitem"
                  >Trocar minha senha</a
                >
              </li> -->
            </ul>
            <div class="py-2">
              <a
                [routerLink]="
                  currentLoggedType === 'user' ? 'login' : linkToAcessoClient
                "
                class="block px-4 py-2 text-sm text-red-700 hover:bg-red-100"
                >Sair</a
              >
            </div>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  </div>
</nav>

<!-- SIDEBAR DOS FORMULÁRIOS -->

<ng-container *ngIf="sidebarVisible" class="z-auto backdrop-blur">
  <p-sidebar
    #sidebarRef
    class="absolute top-10"
    [modal]="sidebarVisible"
    [(visible)]="sidebarVisible"
    position="right"
    (visibleChange)="onToggleNavBar($event)"
    autoZIndex="true"
    baseZIndex="5000"
    styleClass="w-full md:w-1/2 lg:w-1/3"
  >
    <ng-template pTemplate="headless">
      <div
        class="flex justify-between items-center gap-2 bg-white p-4 w-full border-b"
      >
        <span [ngClass]="this.svgIcon" class="text-3xl"></span>
        <h3>{{ formTitle }}</h3>
        <span>
          <button
            type="button"
            (click)="closeCallback($event)"
            class="rounded-full bg-gray-100/30 hover:bg-gray-200/50 p-2 transition-opacity duration-75 hover:text-black"
          >
            <span
              class="icon-[mdi--close] flex text-2xl items-center justify-center"
            ></span>
          </button>
        </span>
      </div>

      <!-- AREA DOS FORMULÁRIOS -->

      <div class="bg-white h-full">
        <ng-container *ngIf="currentForm === 'MY-DATA'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <text-field
                [control]="frmMyData.nome"
                inputId="nome"
                class="bg-transparent border-0 px-0 py-1"
                label="Nome completo"
                [required]="true"
              ></text-field>

              <text-field
                [control]="frmMyData.email"
                inputId="email"
                type="email"
                class="bg-transparent border-0 px-0 py-1"
                label="Email"
                [required]="frmMyData.email.hasValidator(requiredValidator)"
                [inputMode]="'email'"
              ></text-field>

              <text-field
                [control]="frmMyData.cpf"
                inputId="cpf"
                class="bg-transparent border-0 px-0 py-1"
                label="CPF"
                mask="999.999.999-99"
                [viewMask]="true"
                [inputMode]="'numeric'"
              ></text-field>

              <!-- <text-field
                [control]="frmMyData.username"
                inputId="username"
                class="bg-transparent border-0 px-0 py-1"
                label="Primeiro nome ou apelido"
              ></text-field> -->

              <text-field
                [control]="frmMyData.celular"
                inputId="celular"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'tel'"
                label="Celular"
                [required]="frmMyData.celular.hasValidator(requiredValidator)"
                mask="(99) 9999-9999 || (99) 99999-9999"
                [viewMask]="true"
              ></text-field>

              <!-- <text-field
                [control]="frmMyData.telefone"
                inputId="fone"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'tel'"
                label="Telefone"
                mask="(99) 9999-9999 || (99) 99999-9999"
              ></text-field> -->

              <select-field
                [control]="frmMyData.genero"
                inputId="genero"
                label="Gênero"
                [defaultKey]="frmMyData.genero.value"
                specialData="generos"
                [isLoading]="isLoading"
                type="default"
                [fontBold]="true"
                class="self-center m-0"
                ><span class="font-thin text-xs text-topvix-secondary"></span
              ></select-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formMyData.invalid"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'MY-CUSTOMER-DATA'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <text-field
                [control]="frmMyCustomerData.cpf"
                inputId="cpf"
                class="bg-transparent border-0 px-0 py-1"
                label="CPF"
                mask="999.999.999-99"
                [viewMask]="true"
                [inputMode]="'numeric'"
                [readonly]="true"
                [required]="true"
              ></text-field>

              <text-field
                [control]="frmMyCustomerData.nome"
                inputId="nome"
                class="bg-transparent border-0 px-0 py-1"
                label="Nome completo"
                [required]="true"
              ></text-field>

              <text-field
                [control]="frmMyCustomerData.email"
                inputId="email"
                type="email"
                class="bg-transparent border-0 px-0 py-1"
                label="Email"
                [required]="true"
                [inputMode]="'email'"
              ></text-field>

              <text-field
                [control]="frmMyCustomerData.celular"
                inputId="celular"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'tel'"
                label="Celular"
                [required]="
                  frmMyCustomerData.celular.hasValidator(requiredValidator)
                "
                mask="(99) 9999-9999 || (99) 99999-9999"
                [viewMask]="true"
              ></text-field>

              <text-field
                [control]="frmMyCustomerData.dt_nascimento"
                inputId="dt_nascimento"
                type="date"
                [defaultValue]="frmMyCustomerData.dt_nascimento.value"
                [viewMask]="true"
                class="bg-transparent border-0 px-0 py-1"
                label="Data nascimento"
                [maxDate]="maxDateToNascimento"
                inputMode="numeric"
              ></text-field>

              <select-field
                [control]="frmMyCustomerData.genero"
                inputId="genero"
                label="Gênero"
                [defaultKey]="frmMyCustomerData.genero.value"
                specialData="generos"
                [isLoading]="isLoading"
                type="default"
                [fontBold]="true"
                class="self-center m-0"
                ><span class="font-thin text-xs text-topvix-secondary"></span
              ></select-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formMyCustomerData.invalid"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'NEW-USER'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <select-field
                [control]="frmNewUser.perfil_usuario"
                inputId="perfil"
                label="Perfil de acesso"
                type="default"
                [required]="true"
                specialData="perfil_usuario"
                [fontBold]="true"
                class="self-center m-0"
                ><span class="font-thin text-xs text-topvix-secondary"></span
              ></select-field>

              <text-field
                [control]="frmNewUser.nome"
                inputId="nome"
                class="bg-transparent border-0 px-0 py-1"
                label="Nome completo"
                [required]="true"
              ></text-field>

              <select-field
                [control]="frmNewUser.funcao_funcionario"
                [defaultKey]="frmNewUser.funcao_funcionario.value"
                [required]="
                  frmNewUser.funcao_funcionario.hasValidator(requiredValidator)
                "
                inputId="funcao_funcionario"
                label="Cargo do funcionário"
                specialData="cargo_funcionario"
                [fontBold]="true"
                class="self-center m-0"
              ></select-field>

              <text-field
                [control]="frmNewUser.email"
                inputId="email"
                type="email"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'email'"
                label="Email"
                [required]="true"
                tooltip="Email usado para acesso ao sistema."
              ></text-field>

              <!-- <text-field
                [control]="frmNewUser.celular"
                inputId="celular"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'tel'"
                label="Celular"
                mask="(99) 9999-9999 || (99) 99999-9999"
                [required]="frmNewUser.celular.hasValidator(requiredValidator)"
                [viewMask]="true"
              ></text-field> -->

              <text-field
                [control]="frmNewUser.password"
                inputId="password"
                class="bg-transparent border-0 px-0 py-1"
                [inputMode]="'password'"
                label="Senha"
                [required]="frmNewUser.password.hasValidator(requiredValidator)"
              ></text-field>

              <!-- <select-field [control]="frmNewUser.genero" inputId="genero" label="Gênero" specialData="generos"
          [fontBold]="true" class="self-center m-0"><span
            class="font-thin text-xs text-topvix-secondary"></span></select-field> -->
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formNewUser.invalid"
                label="Cadastrar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'MY-PASSWORD'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <text-field
                [control]="frmChPass.password"
                inputId="password"
                class="bg-transparent border-0 px-0 py-1"
                label="Nova Senha"
                [required]="true"
                type="password"
              ></text-field>

              <text-field
                [control]="frmChPass.confirm_password"
                inputId="confim_password"
                class="bg-transparent border-0 px-0 py-1"
                label="Confirmar nova senha"
                type="password"
                [required]="true"
              ></text-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formChangePassword.invalid"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'MY-CUSTOMER-PASSWORD'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <text-field
                [control]="frmCustomerChPass.password"
                inputId="password"
                class="bg-transparent border-0 px-0 py-1"
                label="Nova Senha"
                [required]="true"
                type="password"
              ></text-field>

              <text-field
                [control]="frmCustomerChPass.confirm_password"
                inputId="confim_password"
                class="bg-transparent border-0 px-0 py-1"
                label="Confirmar nova senha"
                type="password"
                [required]="true"
              ></text-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formCustomerChangePassword.invalid"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'SELECT-ACCOUNT'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <select-field
                id="accountSelection"
                [data]="accountList"
                [control]="formSelectAccount.controls.account_id"
                label="Selecione uma empresa"
                [isLoading]="isLoading"
                [loadingCaption]="
                  accountList.length === 0 ? 'Buscando empresas...' : ''
                "
                [required]="true"
                type="default"
              >
              </select-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formSelectAccount.invalid"
                label="Confirmar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'SELECT-CUSTOMER-ACCOUNT'">
          <form class="space-y-4 overflow-auto p-4">
            <!-- <div *ngIf="isLoading" class="w-full flex justify-center items-center gap-2 border-2 rounded-lg px-2 py-1"><span class="icon-[svg-spinners--6-dots-scale] text-2xl"></span>Carregando empresas...</div> -->
            <div class="font-normal text-justify mx-2 mb-4">
              Selecione a outra empresa que você possui pontos.
            </div>

            <select-field
              id="customerAccountSelection"
              [data]="accountList"
              [control]="formSelectAccount.controls.account_id"
              label="Selecione uma empresa"
              [isLoading]="isLoading"
              [loadingCaption]="
                accountList.length === 0 ? 'Buscando empresas...' : ''
              "
              [required]="true"
              type="default"
            >
            </select-field>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formSelectAccount.invalid"
                label="Confirmar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'CONFIG'">
          <form class="space-y-2 overflow-auto p-4">
            <div class="grid gap-4 my-2 p-2 grid-cols-1">
              <text-field
                [control]="frmCfg.qtd_meses_validade_cashback"
                inputId="validade"
                type="number"
                fieldAlign="center"
                class="bg-transparent border-0 px-0 py-1"
                label="Validade dos pontos (meses)"
                [required]="true"
                [readonly]="userService.currentUser.user_role == 'USER'"
                [inputMode]="'numeric'"
              ></text-field>

              <text-field
                [control]="frmCfg.vl_gasto"
                inputId="vl_gasto"
                type="currency"
                [inputMode]="'decimal'"
                [readonly]="userService.currentUser.user_role == 'USER'"
                class="bg-transparent border-0 px-0 py-1"
                label="A cada valor gasto"
                [required]="true"
              ></text-field>

              <text-field
                [control]="frmCfg.pts_por_valor"
                inputId="pontos"
                type="text"
                fieldAlign="center"
                [readonly]="userService.currentUser.user_role == 'USER'"
                class="bg-transparent border-0 px-0 py-1"
                label="Crédito em pontos"
                [required]="true"
                [inputMode]="'numeric'"
                mask="000"
              ></text-field>

              <text-field
                [control]="frmCfg.percentual_cashback_por_vl"
                inputId="percent_cashback"
                type="text"
                fieldAlign="center"
                [inputMode]="'numeric'"
                [readonly]="userService.currentUser.user_role == 'USER'"
                mask="000"
                class="bg-transparent border-0 px-0 py-1"
                label="Percentual cashback"
                [required]="true"
              ></text-field>

              <text-field
                [control]="frmCfg.vl_min_resgate_cashback"
                inputId="vl_min_resgate_cashback"
                type="currency"
                fieldAlign="center"
                [inputMode]="'numeric'"
                [readonly]="userService.currentUser.user_role == 'USER'"
                mask="000"
                class="bg-transparent border-0 px-0 py-1"
                label="Mínimo resgate cashback"
                [required]="true"
              ></text-field>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                [disabled]="formConfig.invalid"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="currentForm === 'MY-LOGO'">
          <form class="space-y-2 overflow-auto mt-2 p-4">
            <div class="grid gap-2 grid-cols-1 p-6">
              <button-template
                *ngIf="!frmImg.imagem.value"
                label="Selecionar imagem"
                icon="icon-[mdi--file-image-plus-outline] text-2xl"
                (onClick)="compressFile()"
              ></button-template>

              <div
                *ngIf="frmImg.imagem.value"
                class="relative flex-1 flex-col justify-center items-center bg-white border border-gray-200 rounded-lg shadow"
              >
                <!-- <span class="icon-[mdi--image-remove-outline] absolute top-0 end-0 text-3xl text-red-500"></span> -->

                <button
                  (click)="clearImage()"
                  type="button"
                  [disabled]="isLoading"
                  ngxTippy="Excluir imagem"
                  [tippyProps]="tippyProps"
                  class="absolute -top-6 -end-6 bg-transparent hover:bg-gray-200 hover:text-gray-900 hover:animate-pulse rounded-lg w-10 h-10 ms-auto inline-flex justify-center items-center"
                >
                  <span
                    class="icon-[mdi--image-remove-outline] text-4xl text-red-500"
                  ></span>
                  <span class="sr-only">Reset image</span>
                </button>

                <img
                  class="rounded-t-lg object-scale-down mx-auto my-3 p-1"
                  [src]="frmImg.imagem.value"
                  alt=""
                />

                <!-- <div
                  *ngIf="!imageValid"
                  class="flex flex-row justify-center items-center w-full gap-2 m-2 text-red-500 text-md font-thin tracking-tight  mx-auto"
                >
                  <span
                    class="icon-[mdi--warning-circle-outline] text-2xl"
                  ></span>
                  <div>Tamanho acima do permitido</div>
                </div> -->
                <!-- <div
                  [ngClass]="!imageValid ? 'text-red-500 font-bold' : ''"
                  class="px-2 m-2 text-center text-xs font-thin tracking-tight text-gray-900  mx-auto"
                >
                  Tamanho: {{ imageWidth || frmImg.imagem_width.value }}px X
                  {{ imageHeight || frmImg.imagem_height.value }}px
                </div> -->
              </div>

              <div
                class="px-2 m-2 text-center text-xs font-thin tracking-tight text-gray-900 mx-auto"
              >
                {{ frmImg.filename.value }}
              </div>
            </div>

            <div class="flex flex-row justify-end items-center mt-3">
              <button-template
                type="button"
                (onClick)="submitData()"
                [buttonStyle]="'filled'"
                label="Atualizar"
                [isLoading]="isLoading"
                loadingCaption="Aguarde..."
              >
              </button-template>
            </div>
          </form>
        </ng-container>
      </div>
    </ng-template>
  </p-sidebar>
</ng-container>

<!-- <timer-action
  [intervalo]="intervalTimeCheck"
  (onTrigger)="checkPendencias()"
  [isActiveAction]="isActionActivated"
></timer-action> -->
