import { Routes } from '@angular/router';
import { authGuard } from './services/auth.guard';
import { environment } from '../environments/environment';
import { LoginComponent } from './pages/login/login.component';

export const routes: Routes = [
  // { path: '', loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent), pathMatch: 'full' },
  // { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: '', component: LoginComponent },
  // { path: `*/:id_empresa`, loadComponent: () => import('./pages/area-cliente-homepage/area-cliente-homepage.component').then(c => c.AreaClienteHomepageComponent)},
  
  { path: 'homepage', loadComponent: () => import('./pages/site-home-page/site-home-page.component').then(c => c.SiteHomePageComponent)},
  { path: 'login', loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent)},
  { path: 'expired', loadComponent: () => import('./components/expired/expired.component').then(c => c.ExpiredComponent)},
  { path: 'reset', loadComponent: () => import('./pages/reset-password/reset-password.component').then(c => c.ResetPasswordComponent)},
  { path: 'register', loadComponent: () => import('./pages/register/register.component').then(c => c.RegisterComponent)},

  // { path: `${environment.LINK_CADASTRO_ROTA}/:token`, component: LinkCadastroLandpageComponent },

  // { path: 'about', loadComponent: () => import('../app/about/about.component').then(c=>c.AboutComponent)},

  { path: `cadastro/:empid`, loadComponent: () => import('./pages/link-cadastro/link-cadastro-homepage/link-cadastro-homepage.component').then(c => c.LinkCadastroLandpageComponent) },
  { path: `sorteio/:sorteio_id`, loadComponent: () => import('./pages/link-cadastro/link-cadastro-homepage/link-cadastro-homepage.component').then(c => c.LinkCadastroLandpageComponent) },
  { path: `${environment.API_AREA_CLIENTE.Methods.ROTA_AREA_CLIENTE}/:id_loja`, loadComponent: () => import('./pages/area-cliente-homepage/area-cliente-homepage.component').then(c => c.AreaClienteHomepageComponent)},
  
  { path: 'cadastrar/empresa', loadComponent: () => import('./pages/cadastrar/empresa/empresa.component').then(c => c.EmpresaComponent), pathMatch: 'full', title: 'Cadastrar empresa'},
  { path: 'cadastrar/usuario', loadComponent: () => import('./pages/cadastrar/usuario/usuario.component').then(c => c.UsuarioComponent), pathMatch: 'full', title: 'Requisitar acesso'},
  { path: 'cadastrar/cliente/:token', loadComponent: () => import('./pages/cadastrar/cliente/cliente.component').then(c => c.NewClienteByLinkComponent), pathMatch: 'prefix', title: 'Cadastrar cliente'},
  
  { path: 'Dashboard', loadComponent: () => import('./pages/dashboard/dashboard.component').then(c => c.DashboardComponent), canActivate: [authGuard]},
  { path: 'Clientes/:option', loadComponent: () => import('./modules/clientes/clientes.component').then(c => c.ModuloClientesComponent), canActivate: [authGuard]},
  { path: 'Relacionamento/:option', loadComponent: () => import('./modules/relacionamento/relacionamento.component').then(c => c.ModuloRelacionamentoComponent), canActivate: [authGuard]},
  { path: 'Campanhas/:option', loadComponent: () => import('./modules/campanhas/campanhas.component').then(c => c.ModuloCampanhasComponent), canActivate: [authGuard]},
  { path: 'Fidelidade/Resgatar/:cliente_fk', loadComponent: () => import('./modules/fidelidade/fidelidade.component').then(c => c.ModuloFidelidadeComponent), canActivate: [authGuard]},
  { path: 'Fidelidade/:option', loadComponent: () => import('./modules/fidelidade/fidelidade.component').then(c => c.ModuloFidelidadeComponent), canActivate: [authGuard]},
  { path: 'Configuração/:option', loadComponent: () => import('./modules/configuracao/configuracao.component').then(c => c.ModuloConfiguracaoComponent), canActivate: [authGuard]},
  { path: 'Administração/:option', loadComponent: () => import('./modules/administracao/administracao.component').then(c => c.ModuloAdministracaoComponent), canActivate: [authGuard]},
  
  { path: `${environment.API_AUTH.Methods.CUSTOMER_AUTH_LOGIN.slice(1)}`, loadComponent: () => import('./modules/area-cliente/login-cliente/login-cliente.component').then(c => c.LoginClienteComponent)},
  { path: 'reset-senha-cliente', loadComponent: () => import('./modules/area-cliente/reset-senha-cliente/reset-senha-cliente.component').then(c => c.ResetSenhaClienteComponent)},
  
  // { path: `${environment.API_AREA_CLIENTE.Methods.PATH_APROVE_REQUEST}/:request_id`, loadComponent: () => import('./modules/area-cliente/area-cliente.component').then(c => c.AreaClienteComponent), canActivate: [authGuard]},
  // { path: `${environment.API_AREA_CLIENTE.Methods.ROTA_AREA_CLIENTE}/:option`, loadComponent: () => import('./modules/area-cliente/area-cliente.component').then(c => c.AreaClienteComponent), canActivate: [authGuard]},

  { path: '**', loadComponent: () => import('./pages/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent) },
];
